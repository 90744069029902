import React from "react";
// reactstrap components
import { Badge, Col, PopoverBody, Row, UncontrolledPopover } from "reactstrap";
import {
  as_currency,
  as_datetime,
  calcNextCosts,
  makeOffer,
  reverse,
  slugify,
  getNow,
} from "../services";
import { renderConfirmModal } from "../reactstrap_services";
import Session from "../session";
import moment from "moment";
import Timer from "../Timer/Timer";
import { TimeContext } from "contexts/TimeContext";
class Good extends React.Component {
  static contextType = TimeContext;
  constructor(props) {
    super(props);
    this.source_colors = {
      cba: "primary",
      sl: "secondary",
      privadas: "warning",
    };
    this.entity_iso = this.props.sources[this.props.good.source].favicon;

    this.state_colors = {
      "Empieza hoy": "primary",
      "Empieza mañana": "secondary",
      "En subasta": "success",
      "Termina hoy": "warning",
      "Termina mañana": "warning",
      Próximo: "default",
      Terminado: "danger",
      Suspendido: "default",
    };
    this.display_name = {
      cba: "Poder Judicial Córdoba",
      sl: "Poder Judicial San Luis",
      privadas: "Subastas Privadas",
    };
    this.floatingStyle1 = {
      width: "30px",
      position: "absolute",
      top: "10px",
      left: "10px",
    };
    this.floatingStyle2 = {
      position: "absolute",
      top: "5px",
      right: "10px",
    };
    this.floatingStyle3 = {
      position: "absolute",
      bottom: "5px",
      right: "10px",
    };
    this.room_btn_style = null;

    this.date_opts = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    };

    this.state = {
      state: "",
      offer_enabled: true,
    };
    // this.classify = this.classify.bind(this);
    this.removeGood = this.removeGood.bind(this);
    this.addToRoom = this.addToRoom.bind(this);
    this.removeFromRoom = this.removeFromRoom.bind(this);
    this.renderTimer = this.renderTimer.bind(this);
    this.renderConfirmModal = renderConfirmModal.bind(this);
    this.makeOffer = this.makeOffer.bind(this);
  }

  addToRoom(event) {
    this.room_btn_style = "btn-danger";
    let id = this.props.good.ext_id || this.props.good.id;
    this.props.addToRoom(id, this.props.good.source);
  }

  removeFromRoom(event) {
    this.room_btn_style = "btn-success";
    let id = this.props.good.ext_id || this.props.good.id;
    this.props.removeFromRoom(id, this.props.good.source);
  }

  makeOffer() {
    if (this.state.offer_enabled) {
      this.setState({ offer_enabled: false });
      let id = this.props.good.ext_id || this.props.good.id;
      var sources = this.props.sources;
      var source = this.props.good.source;
      var toast_container = this.props.toast_container;
      makeOffer(id, sources, source, toast_container);
      setTimeout(() => this.setState({ offer_enabled: true }), 5000);
    }
  }

  removeGood(event) {
    let id = this.props.good.ext_id || this.props.good.id;
    var entity = this.props.good.source;
    this.props.removeGood(id, entity);
  }

  componentDidMount() {
    // this.classify()
  }

  renderName() {
    var name = [
      <h6
        className="card-title text-center d-lg-none"
        style={{
          minHeight: "6vh",
          overflow: "visible",
          textTransform: "uppercase",
        }}
        key={`name_xs_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
      >
        {this.props.good.name}
      </h6>,
      <h5
        className="card-title text-center d-none d-lg-block"
        style={{
          minHeight: "6vh",
          overflow: "visible",
          textTransform: "uppercase",
        }}
        key={`name_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
      >
        {this.props.good.name}
      </h5>,
    ];
    if (this.props.room) {
      name = [
        <h6
          className="card-title text-center d-lg-none"
          style={{
            height: "2vh",
            overflow: "hidden",
            textTransform: "lowercase",
          }}
          key={`title_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
        >
          {this.props.good.name}
        </h6>,
        <h6
          className="card-title text-center d-none d-lg-block"
          style={{
            height: "3vh",
            overflow: "hidden",
            textTransform: "lowercase",
          }}
          key={`titlelg_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
        >
          {this.props.good.name}
        </h6>,
      ];
    }
    return name;
  }

  userOffered() {
    var res = false;
    var username = Session.getUsername(this.props.source);
    for (var index in this.props.good.offers) {
      var offer = this.props.good.offers[index];
      if (offer.user === username) {
        res = true;
        break;
      }
    }
    return res;
  }

  renderOfferBtn() {
    var offer_btn = null;
    var suspended = this.props.good.status === "Suspendido";

    const now = getNow(this.context.date, this.context.localTime);

    var started = new Date(now) > new Date(this.props.good.start_date);
    var ended = new Date(now) > new Date(this.props.good.end_date);

    var can_offer =
      !(suspended || !started || ended) && this.state.offer_enabled;
    var action = this.userOffered() ? this.makeOffer : () => this.setState({ show_confirm_modal: true });
    if (this.props.room) {
      let btn_txt = "Terminado";
      if (!ended){
        btn_txt = <b>
                    {as_currency(
                      this.props.good.next_offer,
                      this.props.good.currency.code
                    )}
                  </b>;
      }
      offer_btn = (
        <Row>
          <Col xs={{ size: 9, offset: 0 }} className={"p-0"}>
            <a
              className={`btn ${
                can_offer ? "btn-success" : "btn-default"
              } btn-sm d-block d-sm-none`}
              href={'#'}
              id={`offer_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              // onClick={(e) => this.makeOffer()}
              onClick={(e)=>{e.preventDefault();action()}}
            >
              {btn_txt}
            </a>
            <a
              className={`btn ${
                can_offer ? "btn-success" : "btn-default"
              } btn-sm d-none d-sm-block mx-1`}
              href={'#'}
              id={`offer_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              // onClick={(e) => this.makeOffer()}
              onClick={(e)=>{e.preventDefault();action()}}
            >
              {btn_txt}
            </a>
            <UncontrolledPopover
              trigger="hover"
              placement="left"
              target={`offer_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
            >
              <PopoverBody>
                <b>{can_offer ? "Ofertar!" : "No se puede ofertar!"}</b>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
          <Col xs={3} className={"p-0"}>
            <a
              className={"btn btn-danger btn-sm d-block d-sm-none"}
              href={"#"}
              id={`remove_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              onClick={(e) => this.removeGood(e)}
            >
              <i className={"fa fa-close"} />
            </a>
            <a
              className={"btn btn-danger btn-sm d-none d-sm-block mx-1"}
              href={"#"}
              id={`remove_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              onClick={(e) => this.removeGood(e)}
            >
              <i className={"fa fa-close"} />
            </a>
            <UncontrolledPopover
              trigger="hover"
              placement="left"
              target={`remove_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
            >
              <PopoverBody>
                <b>Quitar de Mi Sala</b>
              </PopoverBody>
            </UncontrolledPopover>
          </Col>
        </Row>
      );
    }
    return offer_btn;
  }

  renderPrice() {
    var price = (
      <div className={`text-center ${this.state_colors[this.state.state]}}`}>
        <h6 className={"text-center d-lg-none"}>
          {as_currency(this.props.good.price, this.props.good.currency.code)}
        </h6>
        <h5 className={"text-center d-none d-lg-block"}>
          {as_currency(this.props.good.price, this.props.good.currency.code)}
        </h5>
      </div>
    );
    if (this.props.room) {
      price = (
        <div className={`text-center`}>
          <h6 className={"text-center d-lg-none"}>
            {as_currency(this.props.good.price, this.props.good.currency.code)}
          </h6>
          <h5 className={"text-center d-none d-lg-block"}>
            {as_currency(this.props.good.price, this.props.good.currency.code)}
          </h5>
        </div>
      );
    }
    return price;
  }

  renderRoomBtn() {
    var toggle_room_btn = null;
    var cond1 = this.props.good.display_room_btn;
    var cond2 = !this.props.room;
    var cond3 = Session.getUsername(this.props.good.source);
    var suspended = this.props.good.status === "Suspendido";
    var cond4 =
      new Date(this.props.good.end_date) >
      new Date(getNow(this.context.date, this.context.localTime));
    if (cond1 && cond2 && cond3 && cond4 && !suspended) {
      let status;
      if (this.room_btn_style) {
        status = this.room_btn_style;
      } else if (this.props.good.in_room) {
        status = "btn-danger";
      } else {
        status = "btn-success";
      }
      var style = `btn btn-sm ${status} text-white m-1`;
      if (this.props.good.in_room) {
        toggle_room_btn = (
          <>
            <a
              className={style}
              style={this.floatingStyle3}
              id={`remove_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              onClick={(e) => this.removeFromRoom(e)}
            >
              <i className={"fa fa-minus"} />
            </a>
            <UncontrolledPopover
              trigger="hover"
              placement="left"
              target={`remove_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
            >
              <PopoverBody>
                <b>Quitar de Mi sala de subastas</b>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        );
      } else {
        toggle_room_btn = (
          <>
            <UncontrolledPopover
              trigger="hover"
              placement="left"
              target={`add_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
            >
              <PopoverBody>
                <b>Añadir a Mi sala de subastas</b>
              </PopoverBody>
            </UncontrolledPopover>
            <a
              className={style}
              style={this.floatingStyle3}
              id={`add_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
              onClick={(e) => this.addToRoom(e)}
            >
              <i className={"fa fa-plus"} />
            </a>
          </>
        );
      }
    }
    return toggle_room_btn;
  }

  renderTimer() {
    var res = null;
    var good = this.props.good;
    var cond1 =
      new Date(good.start_date) <
      new Date(getNow(this.context.date, this.context.localTime));
    var cond2 =
      new Date(good.end_date) >
      new Date(getNow(this.context.date, this.context.localTime));
    var suspended = this.props.good.status === "Suspendido";

    if (good.ws_error) {
      res = (
        <a
          className={"btn btn-danger text-white"}
          onClick={() => window.location.reload()}
        >
          Reconectar
        </a>
      );
    } else if (cond1 && cond2 && !suspended) {
      res = (
        <div id={"timer"}>
          {
            <Timer
              end_date={new Date(good.end_date)}
              view={
                typeof this.props.view === "undefined" ? true : this.props.view
              }
              callback={
                this.props.refreshGood
                  ? () => setTimeout(() => this.props.refreshGood(good), 5000)
                  : () => null
              }
              localTime={getNow(this.context.date, this.context.localTime)}
            />
          }
        </div>
      );
    }
    return res;
  }

  renderFinalPrice() {
    var res = null;
    var next_fprice = calcNextCosts(this.props.good).total;
    if (this.props.room) {
      res = (
        <>
          <a
            className={`badge badge-pill badge-success`}
            id={`finalprice_${this.props.good.ext_id || this.props.good.id}`}
            href={"#"}
          >
            {as_currency(next_fprice, this.props.good.currency.code)}
          </a>
          <UncontrolledPopover
            trigger="hover"
            placement="auto"
            target={`finalprice_${this.props.good.ext_id || this.props.good.id}`}
          >
            <PopoverBody>
              <div className={`text text-success`}>
                <b>Próxima oferta más comisiones e impuestos.</b>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      );
    }
    return res;
  }

  render() {
    // console.log(this.context.date);
    // console.log(new Date(this.context.date));
    moment.locale("es");
    var url_args = [
      this.props.good.source,
      this.props.good.ext_id || this.props.good.id,
      slugify(this.props.good.name),
    ];
    var popbody = (
      <PopoverBody>
        <b>Inicia</b> el{" "}
        {as_datetime(this.props.good.start_date, this.date_opts)}
        <br />
        <b>Termina</b> el{" "}
        {as_datetime(this.props.good.end_date, this.date_opts)}
      </PopoverBody>
    );
    if (!this.props.good.end_date) {
      popbody = (
        <PopoverBody>
          <b>Termina</b> el{" "}
          {as_datetime(this.props.good.start_date, this.date_opts)}
        </PopoverBody>
      );
    }
    if (this.props.good.suspension) {
      popbody = (
        <PopoverBody>
          <div className={"text text-danger"}>
            {this.props.good.suspension.reason}
          </div>
        </PopoverBody>
      );
    }
    return (
      <Col xl={"3"} lg={"3"} md={"3"} sm={"6"} xs={"6"} className={"p-2"}>
        {/* <Col className={'p-2'} style={{width:'20vw'}}> */}
        <div className="card">
          <a /*{href={`${this.sources[this.props.good.source]}${this.props.good.uri}`}}*/
            href={reverse(
              "good_view",
              this.props.site,
              url_args,
              {},
              this.props.sources
            )}
          >
            <Col lg={"12"}>
              <Row>
                <img
                  src={this.props.good.mini_photo}
                  className={"card-img-top"}
                  alt={"..."}
                />
                <img
                  className={"img img-responsive"}
                  src={this.entity_iso}
                  style={this.floatingStyle1}
                  data-toggle={"tooltip"}
                  title={this.props.sources[this.props.good.source].name}
                />
                <Badge
                  color={this.state_colors[this.props.good.status]}
                  style={this.floatingStyle2}
                  id={`state_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
                >
                  {this.props.good.status}
                </Badge>
                {this.props.room ? (
                  <Badge
                    color={`danger`}
                    style={this.floatingStyle3}
                    id={`bidder_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
                  >
                    {this.props.last_bidder}
                  </Badge>
                ) : (
                  <Badge
                    color={"secondary"}
                    style={this.floatingStyle3}
                    id={`cat_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
                  >
                    {this.props.good.category.name}
                  </Badge>
                )}
                <UncontrolledPopover
                  trigger="hover"
                  placement="left"
                  target={`state_${this.props.good.ext_id || this.props.good.id}_${this.props.good.source}`}
                >
                  {popbody}
                </UncontrolledPopover>
              </Row>
            </Col>
          </a>
          <div className={`card-body`}>
            <a
              href={reverse(
                "good_view",
                this.props.site,
                url_args,
                {},
                this.props.sources
              )}
            >
              {this.renderName()}
              {this.renderPrice()}
            </a>
            {this.renderTimer()}
            {this.renderOfferBtn()}
            {this.renderRoomBtn()}
            {this.renderFinalPrice()}
            {this.props.good ? this.renderConfirmModal(this.props.good) : null}
            {/*<a href="#" className="btn btn-primary">Go somewhere</a>*/}
          </div>
        </div>
      </Col>
    );
  }
}

export default Good;
